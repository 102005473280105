import { handleActions } from 'redux-actions'
import { mailAbTestCampaignMapAction as action } from 'actions'

const initialState = {
  items: [],
  isFetching: false,
}


export const mailAbTestCampaignMap = handleActions({
  [action.fetchMailAbTestCampaignMaps]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [action.fetchMailAbTestCampaignMapsSuccess]: (state, action) => ({
    ...state,
    isFetching: false,
    items: action.payload,
  }),
  [action.fetchMailAbTestCampaignMapsFailure]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [action.searchMailAbTestCampaignMaps]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [action.searchMailAbTestCampaignMapsSuccess]: (state, action) => ({
    ...state,
    isFetching: false,
    items: action.payload,
  }),
  [action.searchMailAbTestCampaignMapsFailure]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [action.clearMailAbTestCampaignMaps]: (state) => ({
    ...state,
    items: [],
  }),
}, initialState)
