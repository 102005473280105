import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {
  Alert, Button, FormGroup, Row, Col, Table, Card, CardBody, CardHeader, Input
} from 'reactstrap'
import {
  FieldSingle, Label, Loading, Swal,
} from 'lib/components'
import { mailAbTestConstant as ab, generalConstant as gc } from 'config/constants'
import { mailAbTestAction } from 'actions'

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  testType: Yup.string().required(),
  remark: Yup.string(),
})

export const MailAbTestForm = ({
  mailAbTest, mailAbTestCampaignMap, item, submitAction, allCampaign, dispatch,
}) => {
  const mappingCampaignIds = mailAbTestCampaignMap.items.map(x => x.campaignId)
  const selectedCampaigns = allCampaign.items.filter(x => mappingCampaignIds.includes(x.id))
  // 新規作成時あるいは編集中のABテストが「準備中」の場合は編集可能
  const editable = item.status === undefined || item.status === 'in_preparation'
  return (
    <Loading isLoading={allCampaign.isFetching}>
      <Formik
        // NOTICE:mailAbtest:{campaigns:[]}の入れ子でAPIへ送信するためvaluesにcampaignsを定義
        initialValues={{
          name: item.name || '',
          testType: item.testType || '',
          remark: item.remark || '',
          campaignType: selectedCampaigns[0]?._camGroup || 'abandoned',
          campaignId: '',
          campaigns: selectedCampaigns || [],
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (submitAction === 'update') {
            dispatch(mailAbTestAction.submitUpdateMailAbTest({ id: item.id, ...values }))
          } else {
            dispatch(mailAbTestAction.submitCreateMailAbTest({ ...values, enabled: false, status: 0 }))
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form noValidate>
            <div>
              { !editable &&
                <Alert color="warning">
                  実行・停止中または終了したABテストは備考のみ編集できます。
                </Alert>
              }
            </div>
            { mailAbTest.error && <Alert color="danger">{mailAbTest.error}</Alert> }
            <legend className="mb-3">ABテスト基本設定</legend>
            <fieldset>
              <FormGroup>
                <Label require>テスト名称</Label>
                <FieldSingle name="name" type="text" disabled={!editable} />
              </FormGroup>
              <FormGroup>
                <Label require>テスト内容</Label>
                <FieldSingle
                  type="select"
                  name="testType"
                  options={ab.testTypes}
                  disabled={!editable}
                />
              </FormGroup>
              <FormGroup>
                <Label>備考</Label>
                <FieldSingle name="remark" type="text" />
              </FormGroup>
            </fieldset>
            <fieldset>
              <TargetCampaignForm
                formCampaigns={values.campaigns}
                campaignId={values.campaignId}
                campaignType={values.campaignType}
                filteredCampaigns={allCampaign.items.filter(campaign => campaign._camGroup === values.campaignType)}
                setFieldValue={setFieldValue}
                editable={editable}
              />
            </fieldset>
            <div className="text-center"><Button color="primary" type="submit" size="lg" disabled={values.campaigns.length < 2}>保存</Button></div>
          </Form>
        )}
      </Formik>
    </Loading>
  )
}

/**
 * ABテストをするキャンペーンを選択するコンポーネント
 */
const TargetCampaignForm = ({ formCampaigns, campaignId, campaignType, filteredCampaigns, setFieldValue, editable }) => {
  // filteredCampaignsをそのままcampaignOptionsに代入すると値渡しになってしまうため、新しい配列を作成する
  let campaignOptions = [...filteredCampaigns]
  if (formCampaigns.length > 0) {
    const target = formCampaigns[0]
    campaignOptions = filteredCampaigns.filter(campaign => !formCampaigns.includes(campaign))
      .filter(campaign => campaign.triggerCampaignId === target.triggerCampaignId)
      .filter(campaign => {
        switch (campaign._camGroup) {
        case 'abandoned':
          return campaign.abandonedType === target.abandonedType
        case 'completed':
          return campaign.campaignType === target.campaignType
        case 'contextual':
          return campaign.targetAction === target.targetAction && campaign.triggerType === target.triggerType
        default:
          return false
        }
      })
  }
  campaignOptions = campaignOptions.map(campaign => <option key={campaign.id} value={campaign.id}>{campaign.name}</option>)
  campaignOptions.unshift(<option key="0"></option>)

  function addFormCampaign() {
    const selectedCampaign = filteredCampaigns.find(campaign => campaign.id === parseInt(campaignId))
    setFieldValue('campaigns', [selectedCampaign, ...formCampaigns])
    setFieldValue('campaignId', '')
  }

  return (
    <>
      <Card className="card-default">
        <CardHeader>
          <div>
            <legend className="mb-3">テストするキャンペーンを選ぶ</legend>
          </div>
          <div>
            <Alert color="dark">
              キャンペーンの配信割合はキャンペーン数で等分になります。<br />
              効果測定にはある程度の配信数が必要になりますので、２〜３つのキャンペーン設定を推奨します。
            </Alert>
          </div>
        </CardHeader>
        <CardBody>
          { (formCampaigns.length > 0 && formCampaigns[0]._camGroup !== campaignType) &&
            <Alert color="warning">
              すでに追加したキャンペーンと異なるキャンペーンタイプは追加することができません。<br />
              キャンペーンを選び直したい場合は「選び直す」ボタンを押してください。
            </Alert>
          }
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>キャンペーンタイプ</Label>
                <Field
                  name="campaignType"
                  type='select'
                  className="custom-select"
                  disabled={!editable}
                  as={Input}
                  onChange={(e) => {
                    setFieldValue('campaignType', e.target.value)
                    setFieldValue('campaignId', '')
                  }}
                >
                  {ab.campaignTypes.map(campaignType=> <option key={campaignType[1]} value={campaignType[1]}>{campaignType[0]}</option>)}
                </Field>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>キャンペーン名</Label>
                <Field
                  name="campaignId"
                  type="select"
                  className="custom-select"
                  disabled={!editable}
                  as={Input}
                >
                  { campaignOptions }
                </Field>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>&nbsp;</Label>
                <div className="text-left">
                  <Button
                    color="primary"
                    size="lg"
                    disabled={!editable || campaignId === ''}
                    onClick={addFormCampaign}
                  >
                    追加する
                  </Button>
                </div>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>&nbsp;</Label>
                <div>
                  <Swal
                    color="warning"
                    size="lg"
                    disabled={!editable || formCampaigns.length === 0}
                    callback={isConfirm => {
                      if (!isConfirm) return
                      setFieldValue('campaigns', [])
                      setFieldValue('campaignId', '')
                    }}
                    options={{
                      ...gc.deleteSwalOptions,
                      title: 'キャンペーンを選び直しますか？',
                      text: 'キャンペーンを選びなおすと、現在選択しているキャンペーンはリセットされます。',
                      buttons: ['キャンセル', '実行'],
                    }}
                  >
                    選び直す
                  </Swal>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardBody>
          <FormGroup>
            <legend>テスト対象キャンペーン一覧</legend>
            <Table bordered responsive>
              <thead>
                <tr style={{ wordBreak: 'keep-all' }}>
                  <th>キャンペーン名</th>
                </tr>
              </thead>
              <tbody>
                { formCampaigns.map(campaign => (
                  <tr key={campaign.id}>
                    <td>{campaign.name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </FormGroup>
        </CardBody>
      </Card>
    </>
  )
}

const select = ({
  mailAbTest, mailAbTestCampaignMap, abandonedCampaign, completedCampaign, contextualCampaign,
}, { mailAbTestId, submitAction }) => {
  let item = mailAbTest.items.find(obj => obj.id === mailAbTestId) || {}
  const abanItems = abandonedCampaign.items.map((x) => { x._camGroup = 'abandoned'; return x })
  const compItems = completedCampaign.items.map((x) => { x._camGroup = 'completed'; return x })
  const contItems = contextualCampaign.items.map((x) => { x._camGroup = 'contextual'; return x })
  return {
    mailAbTest,
    mailAbTestCampaignMap,
    item,
    submitAction,
    allCampaign: {
      items: [...abanItems, ...compItems, ...contItems],
      isFetching: abandonedCampaign.isFetching || completedCampaign.isFetching || contextualCampaign.isFetching || mailAbTest.isFetching || mailAbTestCampaignMap.isFetching,
    },
  }
}

export default connect(select)(MailAbTestForm)
