import React from 'react'
import PropTypes from 'prop-types'

const ErrorText = ({ children }) => {
  if (!children) return null
  return <div className="text-danger mt-1" style={{ fontSize: '12px' }}>{children}</div>
}

ErrorText.propTypes = {
  children: PropTypes.string,
}

export default ErrorText
