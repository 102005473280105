import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Formik, Form, Field } from 'formik'
import { abandonedCampaignAction, aiOptimizedCampaignEmailTrackingAction } from 'actions'
import { Loading } from 'lib/components'
import {
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from 'reactstrap'

function AiOptimizedCampaignEmailTrackingTable({ dispatch, items, selectedCampaign }) {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  if (!selectedCampaign) return null
  if (items.length === 0) return <p className="mt-3">AI配信時間最適化の蓄積データがないため、レポートを表示できません。</p>

  function handleClick() {
    dispatch(abandonedCampaignAction.completeAiOptimized({ campaignId: selectedCampaign.id, schedule: items[0].schedule }))
    toggle()
  }

  const trackings = items.map((item) => {
    // ターゲットアクションからの経過時間
    const schedule = (item.schedule / 60 < 1) ? `${item.schedule} 分` : `${item.schedule / 60} 時間`
    // 試行回数
    const total = item.openedCount + item.unopenedCount
    // 開封率
    const openRate = Math.floor((item.openedCount / total) * 100)
    return {
      id: item.id,
      schedule,
      total: `${total} 回`,
      openRate: `${openRate} %`,
    }
  })

  return (
    <>
      {selectedCampaign.aiOptimizedStatus === 'completed' && (
        <Alert color="success" className="mt-3">{`ターゲットアクションからの経過時間として、試行回数が最多の${trackings[0].schedule}にAI最適化済みです。`}</Alert>
      )}
      <Table className="my-3">
        <thead>
          <tr>
            <th>ターゲットアクションからの経過時間</th>
            <th>試行回数</th>
            <th>開封率</th>
          </tr>
        </thead>
        <tbody>
          { trackings.map((tracking) => (
            <tr key={tracking.id}>
              <td>{ tracking.schedule }</td>
              <td>{ tracking.total }</td>
              <td>{ tracking.openRate }</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="text-center">
        <Button 
          color="primary" 
          onClick={toggle} 
          disabled={selectedCampaign.aiOptimizedStatus !== 'in_progress'}
        >
          AI最適化完了
        </Button>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader>AI配信時間自動最適化</ModalHeader>
          <ModalBody>
            <p style={{ whiteSpace: 'pre-wrap' }}>
              {`本キャンペーンのターゲットアクションからの経過時間として、試行回数が最多の${trackings[0].schedule}を設定します。\nよろしいですか？`}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleClick}>設定する</Button>
            <Button color="secondary" onClick={toggle}>キャンセル</Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  )
}

export const AiOptimizedCampaignReportPage = ({ dispatch, abandonedCampaigns, aiOptimizedCampaignEmailTrackings, isFetching }) => {
  useEffect(() => {
    // aiOptimizedCampaignEmailTrackingのストアを初期化
    dispatch(aiOptimizedCampaignEmailTrackingAction.resetAiOptimizedCampaignEmailTracking())
    dispatch(abandonedCampaignAction.searchAbandonedCampaigns({ ai_optimized_status: ['in_progress', 'completed'] }))
  }, [dispatch])
  
  const [selectedCampaignId, setSelectedCampaignId] = useState(null)
  const selectedCampaign = abandonedCampaigns.find((campaign) => campaign.id === selectedCampaignId)

  function handleSubmit(values) {
    dispatch(aiOptimizedCampaignEmailTrackingAction.fetchAiOptimizedCampaignEmailTracking(values.campaignId))
    setSelectedCampaignId(parseInt(values.campaignId))
  }

  return (
    <ContentWrapper>
      <div className="content-heading">
        AI配信時間最適化レポート
      </div>
      <div className="col-sm-12 col-xl-7">
        <Card className="card-default">
          <CardBody>
            <Formik
              initialValues={{
                campaignId: abandonedCampaigns[0]?.id,
              }}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              <Form>
                <FormGroup>
                  <Label for="campaignId">
                    AI配信時間最適化キャンペーン
                  </Label>
                  <Field
                    id="campaignId"
                    name="campaignId"
                    type="select"
                    as={Input}
                  >
                    { abandonedCampaigns.map((campaign) => (<option key={campaign.id} value={campaign.id}>{ campaign.name }</option>)) }
                  </Field>
                </FormGroup>
                <div className="text-center">
                  <Button color="primary" type="submit">レポートを更新</Button>
                </div>
              </Form>
            </Formik>
            <Loading isLoading={isFetching}>
              <AiOptimizedCampaignEmailTrackingTable
                items={aiOptimizedCampaignEmailTrackings}
                selectedCampaign={selectedCampaign}
                dispatch={dispatch}
              />
            </Loading>
          </CardBody>
        </Card>
      </div>
    </ContentWrapper>
  )
}

const mapStateToProps = (state) => ({
  abandonedCampaigns: state.abandonedCampaign.items,
  aiOptimizedCampaignEmailTrackings: state.aiOptimizedCampaignEmailTracking.items,
  isFetching: state.aiOptimizedCampaignEmailTracking.isFetching,
})
export default connect(mapStateToProps)(AiOptimizedCampaignReportPage)
