import React from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Row, Col, Card, CardBody,
} from 'reactstrap'

import {
  mailAbTestAction,
  abandonedCampaignAction,
  completedCampaignAction,
  contextualCampaignAction,
  mailAbTestCampaignMapAction,
} from 'actions'
import { useMountEffect } from 'lib/hooks'

import MailAbTestForm from 'components/parts/mailAbTest/MailAbTestForm'

export const MailAbTestEditPage = ({dispatch, match}) => {
  const mailAbTestId = Number(match.params.id)

  useMountEffect(() => {
    dispatch(mailAbTestAction.fetchMailAbTests())
    dispatch(mailAbTestCampaignMapAction.searchMailAbTestCampaignMaps(mailAbTestId))
    dispatch(abandonedCampaignAction.searchAbandonedCampaigns({ ai_optimized_status: ['not_optimized'] }))
    dispatch(completedCampaignAction.fetchCompletedCampaigns({ show_finished: false }))
    dispatch(contextualCampaignAction.fetchContextualCampaigns({ show_finished: false }))
  })

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>ABテスト編集</div>
      </div>
      <Row>
        <Col xl={12}>
          <Card className="card-default">
            <CardBody>
              <MailAbTestForm
                mailAbTestId={mailAbTestId}
                submitAction="update"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default connect()(MailAbTestEditPage)
