import { createActions } from 'redux-actions'

const FETCH_ABANDONED_CAMPAIGNS = 'FETCH_ABANDONED_CAMPAIGNS'
const FETCH_ABANDONED_CAMPAIGNS_DONE = 'FETCH_ABANDONED_CAMPAIGNS_DONE'

const SUBMIT_UPDATE_ABANDONED_CAMPAIGN = 'SUBMIT_UPDATE_ABANDONED_CAMPAIGN'
const SUBMIT_UPDATE_ABANDONED_CAMPAIGN_DONE = 'SUBMIT_UPDATE_ABANDONED_CAMPAIGN_DONE'

const SUBMIT_ENABLE_ABANDONED_CAMPAIGN = 'SUBMIT_ENABLE_ABANDONED_CAMPAIGN'
const SUBMIT_ENABLE_ABANDONED_CAMPAIGN_DONE = 'SUBMIT_ENABLE_ABANDONED_CAMPAIGN_DONE'

const SUBMIT_CREATE_ABANDONED_CAMPAIGN = 'SUBMIT_CREATE_ABANDONED_CAMPAIGN'
const SUBMIT_CREATE_ABANDONED_CAMPAIGN_DONE = 'SUBMIT_CREATE_ABANDONED_CAMPAIGN_DONE'

const SUBMIT_DELETE_ABANDONED_CAMPAIGN = 'SUBMIT_DELETE_ABANDONED_CAMPAIGN'
const SUBMIT_DELETE_ABANDONED_CAMPAIGN_DONE = 'SUBMIT_DELETE_ABANDONED_CAMPAIGN_DONE'

const SUBMIT_SEND_ABANDONED_CAMPAIGN_TEST_MAIL = 'SUBMIT_SEND_ABANDONED_CAMPAIGN_TEST_MAIL'
const SUBMIT_SEND_ABANDONED_CAMPAIGN_TEST_MAIL_DONE = 'SUBMIT_SEND_ABANDONED_CAMPAIGN_TEST_MAIL_DONE'

const SEARCH_ABANDONED_CAMPAIGNS = 'SEARCH_ABANDONED_CAMPAIGNS'
const SEARCH_ABANDONED_CAMPAIGNS_SUCCESS = 'SEARCH_ABANDONED_CAMPAIGNS_SUCCESS'
const SEARCH_ABANDONED_CAMPAIGNS_FAILURE = 'SEARCH_ABANDONED_CAMPAIGNS_FAILURE'

const COMPLETE_AI_OPTIMIZED = 'COMPLETE_AI_OPTIMIZED'
const COMPLETE_AI_OPTIMIZED_SUCCESS = 'COMPLETE_AI_OPTIMIZED_SUCCESS'
const COMPLETE_AI_OPTIMIZED_FAILURE = 'COMPLETE_AI_OPTIMIZED_FAILURE'

export const abandonedCampaignAction = createActions(
  {
    [FETCH_ABANDONED_CAMPAIGNS_DONE]: [
      (...args) => args[0],
      (...args) => args[1],
    ],
    [SUBMIT_UPDATE_ABANDONED_CAMPAIGN_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_ENABLE_ABANDONED_CAMPAIGN_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_CREATE_ABANDONED_CAMPAIGN_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
    [SUBMIT_DELETE_ABANDONED_CAMPAIGN_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE', succMess: '終了しました。' }, args[1]),
    ],
    [SUBMIT_SEND_ABANDONED_CAMPAIGN_TEST_MAIL_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign(
        { notification: 'ALL_CASE', succMess: '送信しました。', failMess: '送信に失敗しました。' },
        args[1],
      ),
    ],
    [COMPLETE_AI_OPTIMIZED_SUCCESS]: [
      (...args) => args[0],
      () => Object.assign({ notification: 'SUCCESS', succMess: 'AI最適化が完了しました。' }),
    ],
    [COMPLETE_AI_OPTIMIZED_FAILURE]: [
      (...args) => args[0],
      () => Object.assign({ notification: 'FAILURE', failMess: 'AI最適化に失敗しました。' }),
    ],
  },

  FETCH_ABANDONED_CAMPAIGNS,
  SUBMIT_UPDATE_ABANDONED_CAMPAIGN,
  SUBMIT_ENABLE_ABANDONED_CAMPAIGN,
  SUBMIT_CREATE_ABANDONED_CAMPAIGN,
  SUBMIT_DELETE_ABANDONED_CAMPAIGN,
  SUBMIT_SEND_ABANDONED_CAMPAIGN_TEST_MAIL,
  SEARCH_ABANDONED_CAMPAIGNS,
  SEARCH_ABANDONED_CAMPAIGNS_SUCCESS,
  SEARCH_ABANDONED_CAMPAIGNS_FAILURE,
  COMPLETE_AI_OPTIMIZED,
)
