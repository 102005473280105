import { createActions } from 'redux-actions'

const FETCH_MAIL_AB_TEST_CAMPAIGN_MAPS = 'FETCH_MAIL_AB_TEST_CAMPAIGN_MAPS'
const FETCH_MAIL_AB_TEST_CAMPAIGN_MAPS_SUCCESS = 'FETCH_MAIL_AB_TEST_CAMPAIGN_MAPS_SUCCESS'
const FETCH_MAIL_AB_TEST_CAMPAIGN_MAPS_FAILURE = 'FETCH_MAIL_AB_TEST_CAMPAIGN_MAPS_FAILURE'
const SEARCH_MAIL_AB_TEST_CAMPAIGN_MAPS = 'SEARCH_MAIL_AB_TEST_CAMPAIGN_MAPS'
const SEARCH_MAIL_AB_TEST_CAMPAIGN_MAPS_SUCCESS = 'SEARCH_MAIL_AB_TEST_CAMPAIGN_MAPS_SUCCESS'
const SEARCH_MAIL_AB_TEST_CAMPAIGN_MAPS_FAILURE = 'SEARCH_MAIL_AB_TEST_CAMPAIGN_MAPS_FAILURE'
const CLEAR_MAIL_AB_TEST_CAMPAIGN_MAPS = 'CLEAR_MAIL_AB_TEST_CAMPAIGN_MAPS'

export const mailAbTestCampaignMapAction = createActions(
  {
    [FETCH_MAIL_AB_TEST_CAMPAIGN_MAPS_FAILURE]: [
      (...args) => args[0],
      () => ({ notification: 'FAILURE', failMess: 'ABテストキャンペーン中間テーブル情報の取得に失敗しました。' }),
    ],
    [SEARCH_MAIL_AB_TEST_CAMPAIGN_MAPS_FAILURE]: [
      (...args) => args[0],
      () => ({ notification: 'FAILURE', failMess: 'ABテストキャンペーン中間テーブル情報の検索に失敗しました。' }),
    ],
  },
  FETCH_MAIL_AB_TEST_CAMPAIGN_MAPS,
  FETCH_MAIL_AB_TEST_CAMPAIGN_MAPS_SUCCESS,
  SEARCH_MAIL_AB_TEST_CAMPAIGN_MAPS,
  SEARCH_MAIL_AB_TEST_CAMPAIGN_MAPS_SUCCESS,
  CLEAR_MAIL_AB_TEST_CAMPAIGN_MAPS,
)
