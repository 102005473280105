import {
  takeLatest, takeEvery, put, call, select,
} from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { serializeToQueryString } from 'lib/utils'
import { mailAbTestReportAction } from 'actions'
import * as api from 'services/api'
import { reportConstant as rc } from 'config/constants'
import { baseCampaignTypes } from 'lib/reportUtils'

function* handleSearchMailAbTestReport(action) {
  const targetCampaigns = yield handleExtractTargetCampaigns(action.payload.mailAbTest)
  let searchQuery = searchQueryBuilder(action.payload, targetCampaigns)
  yield put(replace(`/mail_ab_test_report${serializeToQueryString(searchQuery, { isNested: true })}`))
}

function* handleFetchMailAbTestReport(action) {
  const reqValues = fetchActionPayloadToRequest(action.payload)
  // comparisonReport同様、個別のレポート取得はtransitionReportのapiを使う
  let { payload, meta, error } = yield call(api.fetchTransitionReport, reqValues)
  if (!error) {
    payload = {
      ...payload,
      info: action.payload,
    }
  }
  yield put(mailAbTestReportAction.fetchMailAbTestReportDone(payload, meta))
}

/**
 * レポート取得対象のキャンペーン一覧を抽出する
 * @param mailAbTest
 * @returns {Array}
 */
function* handleExtractTargetCampaigns(mailAbTest) {
  const currentState = yield select()
  const { payload } = yield call(api.searchMailAbTestCampaignMaps, mailAbTest)
  let targetCampaigns = extractTargetCampaigns(payload, collectAllCampaigns(currentState)).map(x => `${x._camGroup}${rc.campaignTypeDelimiter}${x.id}`)
  return targetCampaigns
}

const fetchActionPayloadToRequest = (actionPayload) => {
  let { campaign, ...reqValues } = actionPayload
  if (baseCampaignTypes.includes(campaign)) {
    reqValues.campaignType = campaign
  } else {
    const delimiterIndex = campaign.indexOf(rc.campaignTypeDelimiter)
    reqValues.campaignType = campaign.slice(0, delimiterIndex)
    reqValues.campaignId = campaign.slice(delimiterIndex + rc.campaignTypeDelimiter.length)
  }
  return reqValues
}

/**
 * 中間テーブルからABテストに紐づくキャンペーン一覧を抽出する
 * @param mailAbTestCampaignMap
 * @param campaign
 * @returns {Array}
 */
const extractTargetCampaigns = (mailAbTestCampaignMap, campaign) => {
  let targetCampaign = []
  if (mailAbTestCampaignMap.length > 0 && campaign !== undefined) {
    let mappedCampaignIds = mailAbTestCampaignMap.map(x => x.campaignId)
    targetCampaign = campaign.filter(x => isMappedCampaign(x, mappedCampaignIds))
    return targetCampaign
  }
  return targetCampaign
}

/**
 * 対象のキャンペーンがABテストに紐づいているかどうか判定する
 * @param cam
 * @param mappingCampaignsId
 * @param mappingCampaignsType
 * @returns {boolean}
 */
const isMappedCampaign = (cam, mappingCampaignsId) => {
  if (mappingCampaignsId.indexOf(cam.id) < 0) { return false }
  return true
}

/**
 * campaign毎にreport検索実行時のqueryを生成する
 * @param values
 * @param targetCampaigns
 * @returns {Array}
 */
const searchQueryBuilder = (values, targetCampaigns) => {
  let searchQuery = []
  if (targetCampaigns.length > 0) {
    for (let cam of targetCampaigns) {
      let query
      if (values.term === 'free_period') {
        query = {
          mailAbTest: values.mailAbTest, campaign: cam, term: values.term, termFrom: values.termFrom, termTo: values.termTo, transition: values.transition, segmentCondition: values.segmentCondition,
        }
      } else {
        query = {
          mailAbTest: values.mailAbTest, campaign: cam, term: values.term, transition: values.transition, segmentCondition: values.segmentCondition,
        }
      }
      searchQuery.push(query)
    }
  }
  return searchQuery
}

/**
 * Storeから取得した各キャンペーンを配列にまとめる
 * @param currentState
 * @returns {[Array]}
 */
const collectAllCampaigns = (currentState) => {
  if (!currentState) { return null }
  const abanItems = currentState.abandonedCampaign.items.map((x) => { x._camGroup = 'abandoned'; return x })
  const compItems = currentState.completedCampaign.items.map((x) => { x._camGroup = 'completed'; return x })
  const contItems = currentState.contextualCampaign.items.map((x) => { x._camGroup = 'contextual'; return x })

  return [...abanItems, ...compItems, ...contItems]
}

export const mailAbTestReportSaga = [
  takeLatest(mailAbTestReportAction.searchMailAbTestReport, handleSearchMailAbTestReport),
  takeEvery(mailAbTestReportAction.fetchMailAbTestReport, handleFetchMailAbTestReport),
]
