import { createActions } from 'redux-actions'

const FETCH_AI_OPTIMIZED_CAMPAIGN_EMAIL_TRACKING = 'FETCH_AI_OPTIMIZED_CAMPAIGN_EMAIL_TRACKING'
const FETCH_AI_OPTIMIZED_CAMPAIGN_EMAIL_TRACKING_SUCCESS = 'FETCH_AI_OPTIMIZED_CAMPAIGN_EMAIL_TRACKING_SUCCESS'
const FETCH_AI_OPTIMIZED_CAMPAIGN_EMAIL_TRACKING_FAILURE = 'FETCH_AI_OPTIMIZED_CAMPAIGN_EMAIL_TRACKING_FAILURE'
const RESET_AI_OPTIMIZED_CAMPAIGN_EMAIL_TRACKING = 'RESET_AI_OPTIMIZED_CAMPAIGN_EMAIL_TRACKING'

export const aiOptimizedCampaignEmailTrackingAction = createActions(
  FETCH_AI_OPTIMIZED_CAMPAIGN_EMAIL_TRACKING,
  FETCH_AI_OPTIMIZED_CAMPAIGN_EMAIL_TRACKING_SUCCESS,
  FETCH_AI_OPTIMIZED_CAMPAIGN_EMAIL_TRACKING_FAILURE,
  RESET_AI_OPTIMIZED_CAMPAIGN_EMAIL_TRACKING,
)
