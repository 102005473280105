import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import CampaignHtmlFields from 'components/parts/campaign/CampaignHtmlFields'
import { abandonedCampaignConstant as acc, generalConstant as gc } from 'config/constants'
import { arraysToObject } from 'lib/utils'
import { abandonedCampaignAction, mailPreviewAction } from 'actions'
import CampaignMailCodeHelp from 'components/parts/campaign/CampaignMailCodeHelp'
import ManualLink from 'components/parts/manual/ManualLink'
import ValidateDomainButton from 'components/parts/common/ValidateDomainButton'
import {
  Loading,
  FieldSingle,
  FieldCode,
  FieldGroup,
  Label,
  InputGroupAddon,
  MailPreviewButton,
  HelpIcon,
  HintIcon,
  ErrorText,
} from 'lib/components'
import {
  Alert,
  Button,
  FormGroup,
  Input,
  FormText,
  UncontrolledCollapse,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'

// constants
const abandonedTypes = arraysToObject(acc.abandonedTypes, 2, 1)
const followingAbandonedTypes = [abandonedTypes.notPurchasedAfterCampaign, abandonedTypes.notViewedCartAfterCampaign]

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  messageType: Yup.string().required(),
  abandonedType: Yup.number().required(),
  triggerCampaignId: Yup.number()
    .when('abandonedType', {
      is: abandonedType => (followingAbandonedTypes.includes(abandonedType)),
      then: Yup.number().required('該当する先行キャンペーンが存在しません'),
    }),
  purchaseHistory: Yup.string().required(),
  customerType: Yup.number().required(),
  gender: Yup.string().required(),
  schedule: Yup.number().required(),
  aiOptimizedStatus: Yup.bool().required(),
  breakTimeEachCustomer: Yup.number(),
  breakTimePurchasedCustomer: Yup.number(),
  showOutOfStock: Yup.bool().required(),
  resumeItemIsEmpty: Yup.bool().required(),
  stopIfEmailExist: Yup.bool()
    .when('messageType', {
      is: messageType => (messageType !== 'mail'),
      then: Yup.bool().required(),
    }),
  stopIfLineIdExist: Yup.bool()
    .when('messageType', {
      is: messageType => (messageType !== 'line'),
      then: Yup.bool().required(),
    }),
  fromName: Yup.string()
    .when('messageType', {
      is: messageType => (messageType === 'mail'),
      then: Yup.string().notIncludeAngleBrackets().required(),
    }),
  fromAddress: Yup.string()
    .when('messageType', {
      is: messageType => (messageType === 'mail'),
      then: Yup.string().required(),
    }),
  subject: Yup.string()
    .when('messageType', {
      is: messageType => (messageType === 'mail'),
      then: Yup.string().required(),
    }),
  previewText: Yup.string(),
  urlParam: Yup.string(),
  htmlBody: Yup.string(),
  textBody: Yup.string(),
  htmlHeader: Yup.string(),
  style: Yup.string(),
  messages: Yup.string(),
})

export const AbandonedCampaignForm = ({
  company, companyCampaignTemplateValue, abandonedCampaign, mailSenderInfo, item, submitAction, mailAbTestCampaignMap, dispatch,
}) => {
  const isTestAccount = company.item.testAccount
  let isTestDeliverySubmit = false
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const [triggerModal, setTriggerModal] = useState(false)
  const toggleTriggerModal = () => setTriggerModal(!triggerModal)
  const [abTestModal, setAbTestModal] = useState(false)
  const toggleAbTestModal = () => setAbTestModal(!abTestModal)

  // 本キャンペーンが先行キャンペーンとして設定されているかどうか
  const isTriggeredCampaign = abandonedCampaign.items.some(campaign => campaign.triggerCampaignId === item.id)
  // 本キャンペーンがABテストに設定されているかどうか
  const isAbTestedCampaign = mailAbTestCampaignMap.items.some(item => item.campaignId === item.id)

  function preCampaignSelect(type) {
    if (!followingAbandonedTypes.includes(type)) return null

    const triggerCampaigns = abandonedCampaign.items.filter(item => {
      // AI最適化キャンペーンは先行キャンペーンに設定できない
      if (item.aiOptimizedStatus !== 'not_optimized') return false

      if (type === abandonedTypes.notPurchasedAfterCampaign) {
        return item.abandonedType === abandonedTypes.cart
      } else if (type === abandonedTypes.notViewedCartAfterCampaign) {
        return item.abandonedType === abandonedTypes.browser
      }
      return false
    }).map(item => ([item.name, item.id]))
    triggerCampaigns.unshift(['', undefined])

    return (
      <FormGroup>
        <Label require>先行キャンペーン</Label>
        <Field type="select" name="triggerCampaignId" className="custom-select" as={Input}>
          {triggerCampaigns.map((option, index) => (
            <option key={index} value={option[1]}>{option[0]}</option>
          ))}
        </Field>
        <ErrorMessage name="triggerCampaignId" component={ErrorText} />
        <FormText>ステップメールの対象となる1通⽬のキャンペーンを選択してください。</FormText>
      </FormGroup>
    )
  }

  return (
    <Loading isLoading={abandonedCampaign.isFetching || company.isFetching || mailSenderInfo.isFetching}>
      <Formik
        initialValues={{
          name: item.name || '',
          messageType: item.messageType || 'mail',
          abandonedType: item.abandonedType || 1,
          triggerCampaignId: item.triggerCampaignId || undefined,
          purchaseHistory: item.purchaseHistory || 'any_purchased_count',
          customerType: item.customerType || 0,
          gender: item.gender || 'all_gender',
          stopIfLineIdExist: item.stopIfLineIdExist || false,
          stopIfEmailExist: item.stopIfEmailExist || false,
          schedule: item.schedule === 0 || item.schedule ? item.schedule : 30,
          aiOptimizedStatus: ['in_progress', 'completed'].includes(item.aiOptimizedStatus),
          breakTimeEachCustomer: item.breakTimeEachCustomer === 0 || item.breakTimeEachCustomer ? item.breakTimeEachCustomer : 24 * 60,
          breakTimePurchasedCustomer: item.breakTimePurchasedCustomer || undefined,
          fromName: item.fromName || '',
          fromAddress: item.fromAddress || '',
          subject: item.subject || '',
          previewText: item.previewText || '',
          urlParam: item.urlParam || '',
          showOutOfStock: item.showOutOfStock || false,
          resumeItemIsEmpty: item.resumeItemIsEmpty || false,
          htmlBody: item.htmlBody || '',
          textBody: item.textBody || '',
          htmlHeader: item.htmlHeader || '',
          style: item.style || '',
          testAddress: '',
          messages: item.messages || '',
        }}
        validationSchema={validationSchema}
        validate={(values) => {
          if (isTestDeliverySubmit && !values.testAddress) {
            return { testAddress: 'テスト送信時は必須項目です' }
          }
          return {}
        }}
        onSubmit={(values) => {
          if (isTestDeliverySubmit) {
            dispatch(abandonedCampaignAction.submitSendAbandonedCampaignTestMail(values))
            return
          }
          // formikでyup.transformが使えないようなので応急処置
          if (values.breakTimePurchasedCustomer === '0' || !values.breakTimePurchasedCustomer) {
            values = { ...values, breakTimePurchasedCustomer: null }
          }
          // values.aiOptimizedStatusがtrueの場合は'in_progress'を設定し、scheduleはnullにする。falseの場合は'not_optimized'を設定する
          if (values.aiOptimizedStatus) {
            values = { ...values, aiOptimizedStatus: 'in_progress', schedule: null }
          } else {
            values = { ...values, aiOptimizedStatus: 'not_optimized' }
          }
          if (submitAction === 'update') {
            dispatch(abandonedCampaignAction.submitUpdateAbandonedCampaign({ id: item.id, ...values }))
          } else {
            dispatch(abandonedCampaignAction.submitCreateAbandonedCampaign({ ...values, enabled: false }))
          }
        }}
      >
        {({
          values, submitForm, setFieldValue,
        }) => (
          <Form noValidate>
            { abandonedCampaign.error && <Alert color="danger">{abandonedCampaign.error}</Alert> }
            <fieldset>
              <FormGroup>
                <Label require>キャンペーン名称</Label>
                <FieldSingle name="name" type="text" />
              </FormGroup>
              <FormGroup>
                <Label require>送信タイプ</Label>
                <FieldSingle
                  type="select"
                  name="messageType"
                  options={acc.messageType}
                  disabled={
                    item.stage === 'in_the_middle' || item.stage === 'finished'
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label require>ターゲットアクション</Label>
                <FieldSingle
                  type="select"
                  name="abandonedType"
                  options={acc.abandonedTypes}
                  value={() => defaultTargetAction(item.templateTags, values)}
                  disabled={item.stage === 'in_the_middle' || item.stage === 'finished'}
                />
                <FormText>
                  ステップメール（2通目以降）を作成する際は、（後続）が付いたアクションを選択してください。
                </FormText>
              </FormGroup>
              { preCampaignSelect(parseInt(values.abandonedType)) }
            </fieldset>
            <legend className="mb-0">ターゲット顧客属性<HintIcon id="segmentPointTooltip" className="ml-1" style={{ 'fontSize': '14px' }} /></legend>
            <UncontrolledTooltip placement="right" target="segmentPointTooltip">
              ️施策に適した傾向を持つ顧客を配信対象に指定することで、成果指標（開封率・クリック率・完了率）を引き上げる効果が期待できます！
            </UncontrolledTooltip>
            <FormText className="mb-3" tag="span">
              セグメント別配信の設定メニューです。
            </FormText>
            <fieldset>
              <FormGroup>
                <Label require>購入履歴</Label>
                <FieldSingle type="select" name="purchaseHistory" options={acc.purchaseHistories} />
              </FormGroup>
              <FormGroup>
                <Label require>会員種別</Label>
                <FieldSingle type="select" name="customerType" options={acc.customerTypes} />
              </FormGroup>
              <FormGroup>
                <Label require>性別</Label>
                <FieldSingle type="select" name="gender" options={acc.gender} />
              </FormGroup>
              <FormGroup className="mb-0" check>
                { values.messageType !== 'line'
                  && <FieldSingle name="stopIfLineIdExist" type="checkbox" option="LINE IDを取得している顧客には送らない" />
                }
                { values.messageType !== 'mail'
                  && <FieldSingle name="stopIfEmailExist" type="checkbox" option="メールアドレスを取得している顧客には送らない" />
                }
              </FormGroup>
              { values.messageType !== 'line'
              && (
                <FormText className="mt-0">
                  ※LINE連携キャンペーンを利用時にのみ使用する設定です。
                </FormText>
              )
              }
            </fieldset>
            <legend className="mb-3">配信スケジュール設定</legend>
            <fieldset>
              <FormGroup>
                <Label require>ターゲットアクションからの経過時間</Label>
                <HelpIcon id="scheduleTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="scheduleTooltip">
                  お客さまの⾏動の発⽣後どのくらいの時間にメール配信するかを設定します。
                </UncontrolledTooltip>
                <Field type="select" name="schedule" as={Input} className="custom-select" disabled={values.aiOptimizedStatus}>
                  {scheduleTimes(isTestAccount, values.abandonedType, values.triggerCampaignId, abandonedCampaign.items).map((option, index) => (
                    <option key={index} value={option[1]}>{option[0]}</option>
                  ))}
                </Field>
                { values.messageType !== 'line' && [1, 2].includes(Number(values.abandonedType)) // 1: ブラウザ放棄, 2: カート放棄
                  && (
                    <>
                      <FormGroup check>
                        <Field
                          type="checkbox"
                          id="aiOptimizedStatus"
                          name="aiOptimizedStatus"
                          as={Input}
                          onChange={(e) => {
                            // チェックボックスをONからOFFにした場合、かつitem.aiOptimizedStatusが'in_progress', あるいは'completed'の場合は、モーダル表示
                            if (e.target.checked === false && ['in_progress', 'completed'].includes(item.aiOptimizedStatus)) {
                              setModal(true)
                            } else if (e.target.checked === true && isTriggeredCampaign) {
                              setTriggerModal(true)
                            } else if (e.target.checked === true && isAbTestedCampaign) {
                              setAbTestModal(true)
                            } else {
                              setFieldValue(e.target.name, e.target.checked)
                            }}
                          }
                        />
                        <Label check for="aiOptimizedStatus">AIによる配信時間自動最適化を設定する</Label>
                      </FormGroup>
                      <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader>AI配信時間自動最適化</ModalHeader>
                        <ModalBody>
                          <p style={{ whiteSpace: 'pre-wrap' }}>
                            AI配信時間自動最適化を中止しますか？<br />
                            中止した場合、AI最適化に使用している蓄積データは削除されます。<br />
                            よろしいですか？
                          </p>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            color="primary"
                            onClick={() => {
                              setFieldValue('aiOptimizedStatus', false)
                              toggle()
                            }}
                          >
                            中止する
                          </Button>
                          <Button color="secondary" onClick={toggle}>キャンセル</Button>
                        </ModalFooter>
                      </Modal>
                      <Modal isOpen={triggerModal} toggle={toggleTriggerModal}>
                        <ModalHeader>AI配信時間自動最適化</ModalHeader>
                        <ModalBody>
                          <p style={{ whiteSpace: 'pre-wrap' }}>
                            本キャンペーンは先行キャンペーンとして設定されているため、AI配信時間自動最適化を設定できません。<br />
                            後続キャンペーン編集画面にて、本キャンペーンを先行キャンペーンから外してください。
                          </p>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="secondary" onClick={toggleTriggerModal}>キャンセル</Button>
                        </ModalFooter>
                      </Modal>
                      <Modal isOpen={abTestModal} toggle={toggleAbTestModal}>
                        <ModalHeader>AI配信時間自動最適化</ModalHeader>
                        <ModalBody>
                          <p style={{ whiteSpace: 'pre-wrap' }}>
                            本キャンペーンはABテストに設定されているため、AI配信時間自動最適化を設定できません。<br />
                            ABテスト編集画面にて、本キャンペーンをABテストから外してください。
                          </p>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="secondary" onClick={toggleAbTestModal}>キャンセル</Button>
                        </ModalFooter>
                      </Modal>
                    </>
                  )
                }
              </FormGroup>
              { /* 旧管理画面に合わせて後続キャンペーンの場合はOFFにしているが、分岐させる必要があるか不明 */ }
              { !followingAbandonedTypes.includes(Number(values.abandonedType))
                && (
                  <FormGroup>
                    <Label require>一度メールを送信した顧客への最低配信間隔</Label>
                    <HelpIcon id="breakTimeEachCustomerTooltip" className="ml-1" />
                    <FieldSingle type="select" name="breakTimeEachCustomer" options={breakTimeEachCustomer(isTestAccount, values.schedule)} />
                    <UncontrolledTooltip placement="right" target="breakTimeEachCustomerTooltip">
                      同じような内容のメールが連続で配信されないように、一定の期間中配信を停止するための設定です。
                    </UncontrolledTooltip>
                  </FormGroup>
                )
              }
              <FormGroup>
                <Label require>購入後配信停止期間設定</Label>
                <HelpIcon id="breakTimePurchasedCustomerTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="breakTimePurchasedCustomerTooltip">
                  購⼊されたお客さまに対してメール配信を停止する期間を設定します。
                </UncontrolledTooltip>
                <FieldSingle type="select" name="breakTimePurchasedCustomer" options={acc.breakTimePurchasedCustomer} />
              </FormGroup>
            </fieldset>
            <legend className="mb-3">その他条件設定</legend>
            <fieldset>
              <FormGroup>
                <Label>URLパラメータ</Label>
                <HelpIcon id="urlParamTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="urlParamTooltip">
                  キャンペーン内のリンクに自動で付与するURLパラメータを設定できます。<br />
                  外部のアクセス解析ソフトの計測用パラメータの設定などにご利用ください。
                </UncontrolledTooltip>
                <FieldSingle name="urlParam" type="text" placeholder="param1=value2&param2=value2" />
              </FormGroup>
              <FormGroup check>
                <FieldGroup>
                  <Input name="showOutOfStock" type="checkbox" option="在庫のない商品を表示する" />
                  <HelpIcon id="showOutOfStockTooltip" className="ml-1" />
                  <UncontrolledTooltip placement="right" target="showOutOfStockTooltip">
                    在庫なしの商品であっても配信されるメールに表示したい場合にチェックを⼊れます。
                  </UncontrolledTooltip>
                </FieldGroup>
                <FormText className="mb-2">
                  ※ご利用にはあらかじめ在庫情報を連携するタグ（stock_status / stock_count）での在庫情報連携が必要です。
                </FormText>
                <FieldGroup>
                  <Input name="resumeItemIsEmpty" type="checkbox" option="商品情報が無い場合にも配信する" />
                  <HelpIcon id="resumeItemIsEmptyTooltip" className="ml-1" />
                  <UncontrolledTooltip placement="right" target="resumeItemIsEmptyTooltip">
                    お客様の閲覧したあるいはカートに⼊れた商品について、全て商品データに登録されていない場合でもメールを配信したい場合にチェックを⼊れます。
                  </UncontrolledTooltip>
                </FieldGroup>
              </FormGroup>
            </fieldset>
            { values.messageType === 'mail'
              && (
                <>
                  <legend className="mb-3">メール設定</legend>
                  <fieldset>
                    <FormGroup>
                      <Label require>差出人名</Label>
                      <FieldSingle name="fromName" type="text" />
                    </FormGroup>
                    <FormGroup>
                      <Label require>Reply-toアドレス</Label>
                      <HelpIcon id="fromAddressTooltip" className="ml-1" />
                      <UncontrolledTooltip placement="right" target="fromAddressTooltip">
                        SPFレコード設定が必須となります。「SPFチェック」ボタンを押すと、入力したアドレスのSPFレコードが正しく設定できているか確認することができます。
                      </UncontrolledTooltip>
                      <FieldGroup>
                        <Input name="fromAddress" type="text" />
                        <InputGroupAddon addonType="append">
                          <ValidateDomainButton email={values.fromAddress} />
                        </InputGroupAddon>
                      </FieldGroup>
                      <FormText>
                      お客様がリタゲメールに返信した場合の返信先メールアドレスです。
                        <Link to="/campaign_common_setting">全体の初期設定はこちら</Link>
                      </FormText>
                      <FormText>
                        <ManualLink filename="mansys_dns">
                          DNS設定について
                        </ManualLink>
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <Label require>件名</Label>
                      <HintIcon id="subjectPointTooltip" className="ml-1" />
                      <UncontrolledTooltip placement="right" target="subjectPointTooltip">
                        15〜30文字がおすすめです。適切に設定することで開封率がアップする可能性があります！
                      </UncontrolledTooltip>
                      <FieldSingle name="subject" type="text" />
                    </FormGroup>
                    <FormGroup>
                      <Label>プレビューテキスト</Label>
                      <HelpIcon id="previewTextTooltip" className="ml-1" />
                      <UncontrolledTooltip placement="right" target="previewTextTooltip">
                        メール本⽂の概要（ダイジェスト）として受信箱内に表⽰されるテキストです。<br />
                        ※設定されてない場合、メール本⽂のテキスト情報を先頭から順に読み取り表⽰します。
                      </UncontrolledTooltip>
                      <HintIcon id="previewTextPointTooltip" className="ml-1" />
                      <UncontrolledTooltip placement="right" target="previewTextPointTooltip">
                        統⼀性をもたせ不信感を減らし開封率を引き上げる効果が期待できます！
                      </UncontrolledTooltip>
                      <FieldSingle name="previewText" type="text" />
                    </FormGroup>
                  </fieldset>
                  <legend className="mb-3">メール内容</legend>
                  <fieldset>
                    <CampaignHtmlFields
                      company={company}
                      companyCampaignTemplateValue={companyCampaignTemplateValue}
                      formType="abandoned"
                      campaignType={isCartOrCartFollowing(values.abandonedType) ? 'cart' : 'browser'}
                      submitAction={submitAction}
                      values={values}
                      setFieldValue={setFieldValue}
                      dispatch={dispatch}
                    />
                    <FormGroup>
                      <Label>テキストメール用本文</Label>
                      <FieldSingle name="textBody" type="textarea" style={{ minHeight: 200 }} />
                      <FormText>
                        <Link to="/campaign_common_setting">テキストパートの配信設定はこちら</Link>
                      </FormText>
                    </FormGroup>
                    <div className="mb-5">
                      <Button
                        color="secondary"
                        id="codeHelpToggler2"
                        onClick={() => {}}
                      >
                        {' '}
                        コードのヘルプを表示する
                      </Button>
                    </div>
                    <UncontrolledCollapse toggler="#codeHelpToggler2" className="mb-5">
                      <CampaignMailCodeHelp
                        campaignType={isCartOrCartFollowing(values.abandonedType) ? 'cart' : 'browser'}
                      />
                    </UncontrolledCollapse>
                    <div className="mb-5">
                      <MailPreviewButton
                        color="info"
                        onClickCallback={() => { dispatch(mailPreviewAction.submitCreateMailPreview({ ...values, _type: 'abandoned' })) }}
                      >
    メールのプレビュー
                      </MailPreviewButton>
                    </div>
                  </fieldset>
                </>
              )
            }
            { values.messageType === 'line'
              && (
                <>
                  <legend className="mb-3">LINE</legend>
                  <fieldset className="mb-5">
                    <FormGroup>
                      <Label>LINEメッセージ内容</Label>
                      <FieldCode name="messages" />
                    </FormGroup>
                    <div className="mb-2">
                      <Button
                        color="secondary"
                        id="codeHelpToggler3"
                        onClick={() => {}}
                      >
                        {' '}
    コードのヘルプを表示する
                      </Button>
                    </div>
                    <UncontrolledCollapse toggler="#codeHelpToggler3" className="mb-5">
                      <CampaignMailCodeHelp
                        campaignType={isCartOrCartFollowing(values.abandonedType) ? 'cart' : 'browser'}
                      />
                    </UncontrolledCollapse>
                    { abandonedCampaign.processResult.message && abandonedCampaign.processResult.message.match(/LINE/)
                    && (
                      <Alert color="danger">
                      LINE本文の記述に誤りがあるため、保存に失敗しました。
                      </Alert>
                    )
                    }
                  </fieldset>
                </>
              )
            }
            <FormGroup>
              <Label>
                テスト送信先
                { values.messageType === 'mail' ? 'メールアドレス' : 'LINE ID' }
              </Label>
              <FieldGroup>
                <Input name="testAddress" type="text" />
                <InputGroupAddon addonType="append">
                  <Button
                    color="info"
                    onClick={(e) => {
                      isTestDeliverySubmit = true
                      submitForm()
                    }}
                  >
                    テスト送信
                  </Button>
                </InputGroupAddon>
              </FieldGroup>
              <FormText>カンマ区切りで複数入力可能</FormText>
              <FormText>※メール文面に表示されるアイテム情報などは、ランダムに抽出されたものが表示されます。</FormText>
              { values.messageType === 'line'
                && (
                  <FormText>
                    <a href={gc.lineIdCheckUrl} target="_blank" rel="noopener noreferrer">
                    LINE IDを確認する（クリックするとLINE IDが表示されます）
                    </a>
                  </FormText>
                )
              }
            </FormGroup>
            <div className="text-center">
              <Button
                color="primary"
                size="lg"
                onClick={(e) => {
                  isTestDeliverySubmit = false
                  submitForm()
                }}
              >
                保存
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Loading>
  )
}

const isCartOrCartFollowing = abandonedType => (
  [abandonedTypes.cart, abandonedTypes.notPurchasedAfterCampaign].includes(Number(abandonedType))
)


/**
 * 配信スケジュールの選択肢設定用メソッド。
 * 先発キャンペーンがある場合は配信スケジュールに制限がかかる。
 * @param  {Boolean} isTestAccount        検証用アカウント判定
 * @param  {Integer} triggerCampaignId    先発キャンペーンID
 * @param  {Array}   allAbandonedCampaign すべての放棄型キャンペーン
 * @return {Array}                        表示すべき配信スケジュール一覧
 */

const scheduleTimes = (isTestAccount, abandonedType, triggerCampaignId, allAbandonedCampaign) => {
  const rawSheduleTimes = isTestAccount ? acc.scheduleTimesForTestAccount : acc.scheduleTimes
  if (!followingAbandonedTypes.includes(Number(abandonedType)) || !triggerCampaignId) { return rawSheduleTimes }
  const triggerCampaign = allAbandonedCampaign.find(campaign => campaign.id === Number(triggerCampaignId))
  return rawSheduleTimes.filter(schedule => schedule[1] > Number(triggerCampaign.schedule))
}

/**
 * 一度メールを送信した顧客への最低配信間隔の選択肢設定用メソッド。
 * 配信スケジュールに応じて制限がかかる。
 * @param  {Boolean} isTestAccount  検証アカウント判定
 * @param  {Integer} scheduleTime   現在選択されている配信スケジュール
 * @return {Array}                  表示すべき一度メールを送信した顧客への最低配信間隔一覧
 */

const breakTimeEachCustomer = (isTestAccount, scheduleTime) => {
  if (isTestAccount) {
    // 旧管理画面に合わせてテストアカウントはフィルタリングを行わない
    return acc.breakTimeEachCustomerForTestAccount
  }
  return acc.breakTimeEachCustomer.filter(bre => (bre[1] >= Number(scheduleTime)))
}

const defaultTargetAction = (templateTags, values) => {
  if (templateTags) {
    const mappingNames = acc.tagMappingAbandonedTypes.map(m => m.name)
    const mappedTag = templateTags.filter(tag => mappingNames.includes(tag.name))[0]
    if (mappedTag) {
      const abandonedType = acc.tagMappingAbandonedTypes.filter(m => m.name === mappedTag.name)[0].value
      values.abandonedType = abandonedType
      return abandonedType
    }
  }
  return values.abandonedType
}

const select = ({
  abandonedCampaign, company, companyCampaignTemplateValue, mailSenderInfo, campaignTemplate, mailAbTestCampaignMap,
}, { campaignId, templateId, submitAction }) => {
  let item
  let mailContentOverride = {
    fromAddress: mailSenderInfo.item.replyToEmail,
    fromName: mailSenderInfo.item.fromName,
  }
  let campaignContentOverride
  if (templateId) {
    const template = campaignTemplate.items.find(obj => obj.id === templateId) || {}
    campaignContentOverride = {
      subject: template.subject,
      htmlBody: template.htmlBody,
      textBody: template.textBody,
      htmlHeader: template.htmlHeader,
      style: template.style,
      templateTags: template.templateTags,
    }
  }
  if (campaignId) {
    campaignContentOverride = abandonedCampaign.items.find(obj => obj.id === campaignId) || {}
  }
  item = { ...mailContentOverride, ...campaignContentOverride }
  if (submitAction === 'create') {
    item = { ...item, enabled: false, stage: 'in_preparation' }
  }
  return {
    abandonedCampaign, company, companyCampaignTemplateValue, mailSenderInfo, item, submitAction, mailAbTestCampaignMap,
  }
}

export default connect(select)(AbandonedCampaignForm)
