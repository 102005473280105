import { call, put, takeLatest } from 'redux-saga/effects'
import * as api from 'services/api'
import { aiOptimizedCampaignEmailTrackingAction } from 'actions'

function* handleFetchAiOptimizedCampaignEmailTracking(action) {
  const { payload, error } = yield call(api.fetchAiOptimizedCampaignEmailTracking, action.payload)
  if (error) {
    yield put(aiOptimizedCampaignEmailTrackingAction.fetchAiOptimizedCampaignEmailTrackingFailure(payload))
  } else {
    yield put(aiOptimizedCampaignEmailTrackingAction.fetchAiOptimizedCampaignEmailTrackingSuccess(payload))
  }
}

export const aiOptimizedCampaignEmailTrackingSaga = [
  takeLatest(aiOptimizedCampaignEmailTrackingAction.fetchAiOptimizedCampaignEmailTracking, handleFetchAiOptimizedCampaignEmailTracking),
]
