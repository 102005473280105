import { handleActions } from 'redux-actions'
import { aiOptimizedCampaignEmailTrackingAction as action } from 'actions'

const initialState = {
  items: [],
  isFetching: false,
}

export const aiOptimizedCampaignEmailTracking = handleActions({
  [action.fetchAiOptimizedCampaignEmailTracking]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [action.fetchAiOptimizedCampaignEmailTrackingSuccess]: (state, action) => ({
    ...state,
    isFetching: false,
    items: action.payload,
    processResult: 'SUCCESS',
  }),
  [action.fetchAiOptimizedCampaignEmailTrackingFailure]: (state, action) => ({
    ...state,
    isFetching: false,
    processResult: action.payload,
  }),
  [action.resetAiOptimizedCampaignEmailTracking]: () => (initialState),
}, initialState)
