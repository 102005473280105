import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { mailAbTestCampaignMapAction } from 'actions'

function* handleFetchMailAbTestCampaignMaps(action) {
  const { payload, error } = yield call(api.fetchMailAbTestCampaignMaps, action.payload)
  if (error) {
    yield put(mailAbTestCampaignMapAction.fetchMailAbTestCampaignMapsFailure(error))
  } else {
    yield put(mailAbTestCampaignMapAction.fetchMailAbTestCampaignMapsSuccess(payload))
  }
}

function* handleSearchMailAbTestCampaignMaps(action) {
  const { payload, error } = yield call(api.searchMailAbTestCampaignMaps, action.payload)
  if (error) {
    yield put(mailAbTestCampaignMapAction.searchMailAbTestCampaignMapsFailure(error))
  } else {
    yield put(mailAbTestCampaignMapAction.searchMailAbTestCampaignMapsSuccess(payload))
  }
}

export const mailAbTestCampaignMapSaga = [
  takeLatest(mailAbTestCampaignMapAction.fetchMailAbTestCampaignMaps, handleFetchMailAbTestCampaignMaps),
  takeLatest(mailAbTestCampaignMapAction.searchMailAbTestCampaignMaps, handleSearchMailAbTestCampaignMaps),
]
